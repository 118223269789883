
import { defineComponent, reactive, toRefs } from "vue";
// import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { searchInput, TFDATA } from "./config/mtfConfig";
import axios from "axios";
import _ from "lodash";
import movieList from "@/mock/movie";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

export default defineComponent({
  name: "Home",
  components: {
    SearchOutlined,
    // HelloWorld,
  },
  setup() {
    // const router = useRouter();
    const store = useStore();
    let data: any = reactive({
      dataSource: [],
    });

    // let dataSourceAll: any = reactive([]);

    const state = reactive({
      searchText: "",
      searchedColumn: "",
      collectHistory:
        localStorage.getItem("collectHistory")?.trim().split(",") || [],
    });

    // 数据
    const columns = TFDATA;

    const pagination = {
      defaultPageSize: 25,
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ["25", "50", "75", "100"],
      showTotal: (total: number) => `总数${total}`,
      position: ["bottomCenter"],
    };

    const handleSearch = (
      selectedKeys: string,
      confirm: any,
      dataIndex: string
    ) => {
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
    };

    const handleReset = (clearFilters: any) => {
      clearFilters();
      state.searchText = "";
    };

    const goDetail = (record: any) => {
      store.commit("SET_DATA", record);
      localStorage.setItem(record.id, JSON.stringify(record));
      window.open("/mobile/detail/" + record.id);
      // router.push({ name: "Detail", params: record });
    };

    const getList = () => {
      let num = 0;
      movieList.map((movieItem: any) => {
        axios.get(`/data/${movieItem}.json`).then((res) => {
          let { data: datas } = res;
          datas.map((item: any) => {
            item.mTitle = `${item.title}${
              item.imdb
                ? "Imdb评分" + item.imdb + "(" + item.imdb_user + ")"
                : ""
            }${
              item.douban
                ? "豆瓣评分" + item.douban + "(" + item.douban_user + ")"
                : ""
            }`;
            data.dataSource.push(item);
          });
          num++;
          if (num === movieList.length) {
            data.dataSource = _.orderBy(data.dataSource, ["id"], ["desc"]);
            message.success("请求完毕");
          }
        });
      });
    };

    const collect = (id: string) => {
      let num = state.collectHistory.indexOf(id);
      if (num > -1) {
        state.collectHistory.splice(num, 1);
      } else {
        state.collectHistory = [...new Set([...state.collectHistory, ...[id]])];
      }
      if (state.collectHistory.length > 0) {
        localStorage.setItem(
          "collectHistory",
          state.collectHistory
            .filter(function (s) {
              return s && s.trim();
            })
            .toString()
        );
      } else {
        localStorage.removeItem("collectHistory");
      }
    };

    /**
     * @description 处理搜索高亮
     * @param { string }  text 字段内容
     * @param { string }  search 关键词
     * @returns { array }
     */
    const formatTagName = (text: string, search: string) => {
      console.log(text, search);
      let re = new RegExp("(" + search + ")", "g");
      return text.split(re).filter((item) => item);
    };

    return {
      zhCN,
      data,
      columns,
      pagination,
      handleSearch,
      handleReset,
      searchInput,
      goDetail,
      formatTagName,
      collect,
      getList,
      ...toRefs(state),
    };
  },
  created() {
    this.getList();
  },
});
